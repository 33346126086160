<div class="body">
  <div class="col col-1 sar-hide">
    <div>
      <i class="fas fa-file fa-3x"></i>
      <h2>Accédez </h2>
      <p>aux documents de vos dossiers: courriers,<br class="hide"> rapports d’expertise, notes d’honoraires…</p>
    </div>
    <div class="bottom">
      <i class="fas fa-calendar-alt fa-3x"></i>
      <h2>Consultez</h2>
      <p>au quotidien l’avancement de vos dossiers <br class="hide"> d’expertise.</p>
    </div>
  </div>
  <div class="col col-2 sar-hide">
    <div>
      <i class="fas fa-share fa-3x"></i>
      <h2>Transmettez</h2>
      <p>nous par email toute demande de suivi de dossier,<br class="hide"> pour un traitement dans les meilleurs
        délais.</p>
    </div>
    <div class="bottom">
      <i class="fas fa-share-alt fa-3x"></i>
      <h2>Accessible </h2>
      <p>depuis une simple connexion internet<br class="hide"> sans aucune installation.</p>
    </div>
  </div>
  <div class="col col-3 mobile-clear">
    <h3>Accés à l'extranet SIEL</h3>

    <form (ngSubmit)="login()" #loginForm="ngForm" novalidate>
      <mat-form-field appearance="outline">
        <mat-label>Identifiant</mat-label>
        <input matInput placeholder="entrer l'identifiant" [(ngModel)]="userName" name="userName">
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline">
        <mat-label>Mot de passe</mat-label>
        <input matInput placeholder="entrer le mot de passe" [type]="showPwd ? 'text' : 'password'" [(ngModel)]="password" name="password" #pwd="ngModel">
        <mat-icon class="pointer" matSuffix (click)="showPwd = !showPwd">{{showPwd ? 'visibility' : 'visibility_off'}}
        </mat-icon>

      </mat-form-field>
      <div id="captcha"></div>
      <a [routerLink]="['/account/forgot-pwd']" class="pass-forget">Mot de passe oublié?</a>
      <a [routerLink]="['/account/register']" class="pass-forget">Demander un accès</a>
      <br />

      <button class="login-button" mat-flat-button color="primary">Se connecter</button>
      <spinner class="loginSpinner " [isRunning]="isRequesting"></spinner>
    </form>
  </div>
</div>
