export class Paging {
    public PageSize: number;
    public CurrentPage: number;
    public NumberOfPages: number;
    public NumberOfItems: number;
    public CurrentSortDirection: string;
    public CurrentSortColumn: string;

    constructor(currentPage:number, 
        pageSize:number,
        numberOfItems: number,
        numberOfPages: number
        ){
        this.CurrentPage = currentPage;
        this.PageSize = pageSize;
        this.NumberOfItems = numberOfItems;
        this.NumberOfPages = numberOfPages;
        this.CurrentSortDirection = "ASC";
        this.CurrentSortColumn = "";
    }
}
