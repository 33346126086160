import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../shared/services/auth.service';
import { Token } from '../../shared/model/token';
import { DataService } from '../services/data.service';
import { Criterias } from '../model/criterias';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  userFullName:string;
  constructor(private authService: AuthService, private dataService: DataService, private router: Router) { }

  ngOnInit(){
    let token:Token = JSON.parse(localStorage.getItem('auth_token_siel'));
    this.userFullName = token.UserFullName;
  }

  logout(){
      this.authService.logout();
      this.dataService.criterias = new Criterias('', '', '');
      this.dataService.missions = null;
      this.router.navigate(['/account']);
  }

}
