import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserCredential } from '../../shared/model/user-credential';
import { AuthService } from '../../shared/services/auth.service';
import { NotificationService } from '../../shared/utils/notification.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  model: UserCredential;
  requestForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private router: Router, private authService: AuthService, private notif: NotificationService) {
    this.requestForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', [Validators.required]],
      fonction: ['', [Validators.required]],
      agence: ['', [Validators.required]],
      compagnie: ['', [Validators.required]],
      firstName: [''],
      lastName: ['', [Validators.required]]
    });

  }

  ngOnInit() {
    this.model = new UserCredential();
  }

  getCredential() {
    return this.authService.getCredential(this.model)
      .subscribe(result => {
        if (result.IsSuccess) {
          this.notif.printSuccessMessage('Votre demande est prise en compte, nous reviendrons vers vous au plus tôt');
        }
        this.router.navigate(['/account']);
      }, error => {
        this.notif.printErrorMessage('Une erreur s\'est produite veuillez réessayer plus tard');
      });
  }
}
