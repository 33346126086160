<div class="body-id-header">
  <div class="title-id">
    <h2>Modification de mot de passe </h2>
    <p>Veuillez saisir votre identifiant et votre nouveau mot de pass svp.</p>
  </div>
</div>
<div class="body-id">
  <div class="form-id">
    <form [formGroup]="myForm">
      <spinner [isRunning]="isRequesting"></spinner> <br />
      <img src="assets/img/img-anchor-id.jpg" class="hide" alt="anchor">
      <div class="col-id col-id-1">
        <mat-form-field>
          <input matInput placeholder="Nouveau mot de passe" formControlName="password"
            [type]="showPwd ? 'text' : 'password'" [(ngModel)]="model.Password" required />
          <mat-icon class="pointer" matSuffix (click)="showPwd = !showPwd">{{showPwd ? 'visibility' : 'visibility_off'}}
          </mat-icon>
          <mat-error *ngIf="myForm.hasError('required', 'password')">
            Veuillez saisir un mdp
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Confirmation du nouveau mot de passe" type="password"
            formControlName="confirmPassword" [errorStateMatcher]="matcher" required />
          <mat-error *ngIf="myForm.hasError('notSame')">
            Les mots de passe ne correspondent pas
          </mat-error>
        </mat-form-field>
        <div class="btns">
          <a class="sar-btn btn-id-first pointer envoyerBtn" id="envoyer" (click)="updatePassword()">
            Envoyer
          </a>
        </div>
      </div>
    </form>
  </div>
</div>