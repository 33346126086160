<div class="body-id-header">
  <div class="title-id">
    <h2>Demande d’identifiant </h2>
    <p>Nous vous invitons à remplir le formulaire ci-dessous. Cela nous permettra de vous créer un identifiant avec des
      droits d’accès adaptés.</p>

  </div>
</div>
<div class="body-id">
  <div class="form-id">
    <form [formGroup]="requestForm">
      <img src="assets/img/img-anchor-id.jpg" class="hide" alt="anchor">
      <div class="radios sar-hide col">
        <mat-radio-group name="sexe" required>
          <mat-radio-button color="primary" value="m">M. </mat-radio-button>
          <mat-radio-button color="primary" value="mme">Mme </mat-radio-button>
          <mat-radio-button color="primary" value="mlle">Mlle </mat-radio-button>
        </mat-radio-group>
      </div>
      <br />

      <div class="col-md-4 col-xs-14 col">

        <mat-form-field>
          <input matInput type="text" name="nom" class="nom" placeholder="Nom" [(ngModel)]="model.LastName"
            formControlName="lastName" required>
          <mat-error *ngIf="requestForm.hasError('required','lastName')">Le nom est requis</mat-error>
        </mat-form-field>
        <br />
        <mat-form-field>
          <input matInput type="text" name="prenom" class="prenom" placeholder="Prénom" [(ngModel)]="model.FirstName"
            formControlName="firstName">
        </mat-form-field>
        <br />
        <mat-form-field>
          <input matInput type="text" name="compagnie" class="compagnie" placeholder="Compagnie"
            [(ngModel)]="model.Company" formControlName="compagnie" required>
          <mat-error *ngIf="requestForm.hasError('required','compagnie')">La compagnie est requise</mat-error>
        </mat-form-field>
        <br />
        <mat-form-field>
          <input matInput type="text" name="agence" class="agence" placeholder="Agence" [(ngModel)]="model.Agency"
            formControlName="agence" required>
          <mat-error *ngIf="requestForm.hasError('required','agence')">L'agence est requise</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4 col-xs-14 col">

        <mat-form-field>
          <input matInput type="text" name="fonction" placeholder="Fonction" [(ngModel)]="model.Position"
            formControlName="fonction" required>
          <mat-error *ngIf="requestForm.hasError('required','fonction')">La fonction est requise</mat-error>
        </mat-form-field>
        <br />
        <mat-form-field>
          <input matInput type="text" name="email" class="email" placeholder="Email" [(ngModel)]="model.Email"
            formControlName="email" required>
          <mat-error *ngIf="requestForm.hasError('required','email')">L'email est requis</mat-error>
          <mat-error *ngIf="requestForm.hasError('email','email')">L'email n'est pas valide</mat-error>
        </mat-form-field>
        <br />
        <mat-form-field>
          <input matInput type="text" name="telephone" class="telephone" placeholder="Téléphone"
            formControlName="telephone" [(ngModel)]="model.Phone" required>
          <mat-error *ngIf="requestForm.hasError('required','telephone')">Le téléphone est requis</mat-error>
        </mat-form-field>
        <br />

        <p class="obli-champs">*champs obligatoires</p>

        <div class="radios bottomElements">
          <mat-checkbox color="primary" name="contacte" #contact (change)="!contact.checked" required>Je souhaite
            être
            contacté</mat-checkbox>
        </div>
        <div class="action-buttons">
          <button class="confirm-button" mat-flat-button color="primary" (click)="getCredential()"
            *ngIf="requestForm.valid && contact.checked">Envoyer</button>
          <button mat-flat-button color="primary" [routerLink]="['/account']">Annuler</button>
        </div>
      </div>
    </form>
  </div>
</div>
