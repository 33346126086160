import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../shared/services/auth.service';
import { NotificationService } from '../../shared/utils/notification.service';
import { environment } from 'environments/environment.prod';
import { RecaptchaService } from 'app/shared/services/recaptcha.service';
declare const grecaptcha: any;

@Component({
  selector: 'app-forgot-pwd',
  templateUrl: './forgot-pwd.component.html',
  styleUrls: ['./forgot-pwd.component.css']
})
export class ForgotPwdComponent implements OnInit {
  identifiantModel: string;
  constructor(private router: Router, private authService: AuthService, private notif: NotificationService,
    private recaptchaService: RecaptchaService) { }

  ngOnInit() {
    grecaptcha.ready(() => {
      grecaptcha.render(document.getElementById('captcha'), {
        'sitekey': environment.recaptchaSiteKey,
      });
    });
  }

  resetPasswordClick() {
     var response = grecaptcha.getResponse();
     if (response.length === 0) {
       this.notif.printErrorMessage('Veuillez confirmer le captcha svp.');
       return;
     }
     else {
       this.recaptchaService.verify(response).subscribe((result: any) => {
         if (result && result.success) {
          this.resetPassword();
         }
         else {
           this.notif.printErrorMessage('Votre captcha n\'est pas valide. Veuillez reéssayer svp.')
           grecaptcha.reset();
           return;
         }
       });
     }
  }


  resetPassword() {
    return this.authService.resetPassword(this.identifiantModel)
      .subscribe(result => {
        if (result && result.IsSuccess) {
          this.notif.printSuccessMessage('Votre demande est prise en compte, veuillez regarder votre boite mail.');
        }
        else {
          this.notif.printErrorMessage('Une erreur s\'est produite veuillez réessayer plus tard');
        }

        this.router.navigate(['/account']);
      }, error => {
        this.notif.printErrorMessage('Une erreur s\'est produite veuillez réessayer plus tard');
      });
  }

}
