import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
  })
export class AppConfiguration {
    apiUrl: string;
    geopMissionExistsUrl: string;
    geopMissionOpenUrl:string;
    
    constructor(private httpClient: HttpClient) {

    }
    ensureInit(): Promise<any> {
        return new Promise((r, e) => {
            const mr = Math.random();
            this.httpClient.get("./assets/configurations/config.json?v=" + mr)
                .subscribe(
                    (content: AppConfiguration) => {
                        Object.assign(this, content);
                        r(this);
                    },
                    reason => e(reason));
        });
    }
}