import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../services/data.service';
import { AuthService } from '../../shared/services/auth.service';
import { Mission } from '../model/mission';
import { Criterias } from '../model/criterias';
import { MatDialog } from '@angular/material/dialog';
import { EnvoiEmailDialog } from '../dialogs/envoi-mail-dialog/envoi-mail-dialog';
import { CompanyCode } from '../enums/companyCode';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  @Input() isAuthenticated: boolean;
  @Input() isMission: boolean;
  @Input() mission: Mission;
  @Input() isHome: boolean;
  @Input() isBack: boolean;
  @Input() isAdmin: boolean;
  @Input() company: string;
  logo: string;

  constructor(private dataService: DataService,
    private router: Router,
    private authService: AuthService,
    public dialog: MatDialog) { }

  ngOnInit() {
    switch (this.company) {
      case '01':
        this.logo = 'assets/img/logo.png';
        break;
      case '07':
        this.logo = 'assets/img/logo_EQ.png';
        break;
      case '05':
        this.logo = 'assets/img/logo_exetech.png';
        break;
      case '04':
        this.logo = 'assets/img/logo_cebime.png';
        break;
      default:
        this.logo = 'assets/img/logo.png';
        break;
    }
  }

  goToHome() {
    this.dataService.missions = null;
    this.router.navigate(['/home']);
  }

  backToList() {
    this.router.navigate(['/home']);
  }

  logout() {
    this.authService.logout()
      .subscribe(() => {
        this.dataService.criterias = new Criterias('', '', '');
        this.dataService.missions = null;
        this.router.navigate(['/account']);
      },
        (error) => {
          if (!this.authService.isLoggedIn()) {
            this.router.navigate(['/account']);
          }
        });
  }

  sendEmail() {
    let mailTo = 'mailto:serviceclients@saretec.fr';

    if (this.mission &&
      (this.mission.CodeCompagnie == CompanyCode.SOGESSUR || this.mission.CodeCompagnie == CompanyCode.ALLIANZ)) {
      this.showPopupSendMail();
    }
    else if (this.mission) {
      let missionEmailsExist = (this.mission.EmailExpert || this.mission.EmailAgence || this.mission.EmailSecretaire);
      mailTo = missionEmailsExist ?
        `mailto:${this.mission.EmailExpert},${this.mission.EmailAgence},${this.mission.EmailSecretaire}` :
        mailTo;

      window.location.href = mailTo + '?subject=Ref. Expert: '
        + this.mission.NumDossier + ' - Sinistre: ' + this.mission.RefClient
        + ' - Assure: ' + this.mission.NomAffaire + '&body=';
    }
    else {
      window.location.href = mailTo;
    }
  }

  showPopupSendMail() {
    this.dialog.open(EnvoiEmailDialog, {
      data: this.mission
    });
  }
}

