import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(private router: Router, private _notifier: NotificationService) { }

  manageErrors(data: any) {
    let error: any = {};
    if (data) {
      error = data.status ? data : data.error;
    }

    if (error == null) {
      error = {};
    }
    if (error.status && error.status == 401) {
      localStorage.removeItem('auth_token_siel');
      this.router.navigate(['/account']);
      this._notifier.printErrorMessage(
        'Veuillez vous connecter pour effectuer cette action'
      );
      return;
    }

    if (error.status && error.status == 403) {
      this.router.navigate(['/home']);
      this._notifier.printErrorMessage(
        "Vous n'êtes pas autorisés à effectuer cette action"
      );
      return;
    }

    if (error.status && error.status == 404) {
      this.router.navigate(['/home']);
      this._notifier.printErrorMessage(
        "Cette page n'existe pas"
      );
      return;
    }

    if (error && error.Messages) {
      let messages = error.Messages;
      for (let i = 0; i < messages.length; i++) {
        this._notifier.printErrorMessage(messages[i].Description);
      }

      return;
    }
    this._notifier.printErrorMessage(
      "Une erreur s'est produite veuillez réessayer plus tard"
    );
  }
}
