import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-lientransparent',
  template: ''
})
export class LientransparentComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {

      let idMission = params.miss_id;
      let guidMission = params.c_cont || params.rowguid;
      let idDocument = params.doc_id;
      let login = params.p1 || params.login;
      let password = params.p2 || params.pw;
      let refClient = params.miss_rc;

      let refType = params.type;
      let ref: string = params.ref;

      let redirectIdMissionGuidMission = (idMission != null) && (guidMission != null)  && (idDocument == null);
      let redirectIdMissionGuidRowIdDocument = (idMission != null) && (guidMission != null) && (idDocument != null);
      let redirectRefClient = (login != null) && (password != null) && (refClient != null);
      let redirectAutoLogin = (login != null) && (password != null) && !refType && !ref;
      let redirectRefTypeRef = (login != null) && (password != null) && (refType != null) && (ref != null);

      ref = this.treatInputURL(ref);

      if (redirectIdMissionGuidMission) {
        let redirect = '/home/' + idMission + '/' + guidMission;
        this.router.navigate([redirect]);
      } else if (redirectIdMissionGuidRowIdDocument){
        let redirect = '/home/' + idMission + '/' + guidMission + '/' + idDocument;
        this.router.navigate([redirect]);
      } else if (redirectRefClient) {
        let redirect = '/home/' + login + '/' + password + '/client/' + refClient;
        this.router.navigate([redirect]);
      } else if (redirectAutoLogin){
        let redirect = '/home/autologin/' + login + '/' + password;
        this.router.navigate([redirect]);
      } else if (redirectRefTypeRef) {
        let redirect = '/home/' + login + '/' + password + '/' + refType + '/' + ref;
        this.router.navigate([redirect]);
      } else {
        let redirect = '/home/';
        this.router.navigate([redirect]);
      }
    });
  }

  private treatInputURL(ref: string): string {
    if (!ref) {
      return;
    }

    var regexSpaces = /\s|%20/gm;
    ref = ref.replace(regexSpaces, '');
    var regexSlashes = /\/|%2F/gm;
    ref = ref.replace(regexSlashes, '__');

    return ref;
  }

}
