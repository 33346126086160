import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Mission } from "app/shared/model/mission";
import { EmailService } from "app/shared/services/email.service";
import { NotificationService } from "app/shared/utils/notification.service";
import { CompanyCode } from "app/shared/enums/companyCode";
import { EmailAttachment } from "app/shared/model/emailAttachments";
import { LoadingService } from "app/shared/services/loading.service";
import * as moment from 'moment';

@Component({
  selector: 'envoi-mail-dialog',
  templateUrl: './envoi-mail-dialog.html',
  styleUrls: ['./envoi-mail-dialog.css']
})
export class EnvoiEmailDialog {
  fromName: string = "";
  typeMessage: string = "Informations";
  mission: Mission;
  message: string = "";
  showMessageRequired: boolean = false;
  attachments: EmailAttachment[] = [];
  anyFileBiggerThan2Mb: boolean = false;

  get missionRef(): string {
    return `Ref. Expert : ${this.mission.NumDossier} - Sinistre : ${this.mission.RefClient} - Assure : ${this.mission.NomAffaire}`;
  };

  get isRequesting(): boolean {
    return this.loadingService.getRequestingState();
  }

  get requiredFieldsMissing(): boolean {
    return this.fromName.trim().length === 0 || 
           this.message.trim().length === 0;
  }


  constructor(private notificationService: NotificationService,
    public emailService: EmailService,
    private loadingService: LoadingService,
    public dialogRef: MatDialogRef<EnvoiEmailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Mission) {
      this.mission = data;
  }

  uploadFile(event) {
    this.anyFileBiggerThan2Mb = false;

    for (let index = 0; index < event.length; index++) {
      const element = event[index];

      let fileSizeInMb = element.size / 1024 / 1024;
      if (fileSizeInMb > 2) {
        this.anyFileBiggerThan2Mb = true;
        continue;
      }

      let doc = new EmailAttachment();
      doc.Name = element.name;
      this.setFileBytes(element, doc);

      this.attachments.push(doc);
    }
  }

  setFileBytes(file: any, attachment: EmailAttachment) {
    let fileByteArray = [];
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = function (evt: any) {
      if (evt.target.readyState == FileReader.DONE) {
        var arrayBuffer: any = evt.target.result,
          array = new Uint8Array(arrayBuffer);
        for (var i = 0; i < array.length; i++) {
          fileByteArray.push(array[i]);
        }
        attachment.FileBytes = fileByteArray;
      }
    }
  }

  deleteAttachment(index) {
    this.attachments.splice(index, 1)
  }

  sendEmail() {
    if (!this.message || !this.message.trim()) {
      this.message = "";
      this.showMessageRequired = true;
      return;
    }
    else {
      this.showMessageRequired = false;
    }

    this.loadingService.setRequestingState(true);
    const isSogessur = this.data.CodeCompagnie === CompanyCode.SOGESSUR;
    const isAllianz = this.data.CodeCompagnie === CompanyCode.ALLIANZ;
    let to = ["serviceclients@saretec.fr"];
   
    if (isAllianz && this.data.MailGestionnaire)
    {
      to.push(this.data.MailGestionnaire);
    }

    let now = moment().format('LLLL');
    let companyName = isSogessur ? "[Sogessur]" : isAllianz ? "[Allianz]" : `[Compagnie:${this.data.CodeCompagnie}]`;
    
    let subject = `${companyName} ${this.typeMessage} : Message de la part de ${this.fromName} ` + 
                  `: Ref client - ${this.data.RefClient}, - Num Dossier ${this.data.NumDossier}`;
    let body = `Date : ${now}
                Nom de l'émetteur : ${this.fromName}
                Destinataire : ${to.concat()}
                Mission : ${this.data.RefClient}
                Message :
                ${this.message}`;

    this.emailService.sendEmail(to, subject, body, this.attachments).subscribe(result => {

      if (result && result.IsSuccess && result.Value) {
        this.notificationService.printSuccessMessage('Votre message a bien été envoyé. Merci de nous avoir contacté.');
      }
      else {
        this.notificationService.printErrorMessage('Un erreur est survenue dans l\'envoi de votre message. Veuillez nous excuser et reéssayer plus tard.');
      }

      this.loadingService.setRequestingState(false);
      this.dialogRef.close();
    }, error => {
      this.notificationService.printErrorMessage('Un erreur est survenue dans l\'envoi de votre message. Veuillez nous excuser et reéssayer plus tard.');
      this.loadingService.setRequestingState(false);
      this.dialogRef.close();
    });
  }
}