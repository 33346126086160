import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmailMessage } from '../model/emailMessage';
import { EmailAttachment } from '../model/emailAttachments';

@Injectable({
    providedIn: 'root'
  })
export class EmailService {

    constructor(private apiService: ApiService) { }

    sendEmail(to: string[], subject: string, message: string, files: EmailAttachment[]): Observable<any> {
       
        let headers = new HttpHeaders();
        let data = new EmailMessage();
        data.To = to;
        data.Subject = subject;
        data.Content = message;
        data.Attachments = files;

        const post = this.apiService.postData('/api/email/send', data, headers);

        return post;
    }
}