import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/shared/services/auth.service';
import { NotificationService } from 'app/shared/utils/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PasswordUpdate } from 'app/shared/model/passwordUpdate';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordsErrorStateMatcher } from 'app/shared/error-state-matcher/passwords-error-state-matcher';

@Component({
  selector: 'app-update-pwd',
  templateUrl: './update-pwd.component.html',
  styleUrls: ['./update-pwd.component.css']
})
export class UpdatePwdComponent implements OnInit {
  myForm: FormGroup;
  isRequesting: boolean = false;
  model = new PasswordUpdate();
  matcher = new PasswordsErrorStateMatcher();
  showPwd: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notifService: NotificationService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.model.Id = params['guid'];
      this.model.UserName = params['userName'];
    });

    this.myForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['',]
    }, { validator: this.checkPasswords });
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }


  updatePassword() {

    if (!this.myForm.valid) {
      console.log("error in form");
      return;
    }

    this.isRequesting = true;
    this.authService.updatePassword(this.model).subscribe((response) => {
      this.isRequesting = false;
      if (response && response.IsSuccess) {
        let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/login';
        this.notifService.printSuccessMessage('Votre mot de passe a éte mis à jour. Veuillez vous connecter à nouveau pour utiliser l\'application');
        this.router.navigate([redirect]);
      }
      else {
        this.notifService.printErrorMessage('Un erreur est survenue, le lien que vous utilisez a peut etre expiré ?');
      }
    },
      error => {
        this.isRequesting = false;
        this.notifService.printErrorMessage('Un erreur est survenue, veuilez reessayer plus tard svp');
      });
  }

}
