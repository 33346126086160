import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    CanLoad, Route
} from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
  })
export class AuthGuardService {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
        let url: string = state.url;

        return this.checkLogin(url);
    }

    canLoad(route: Route){
        let url = `/$(route.path)`;

        return this.checkLogin(url);
    }

    checkLogin(url: string): boolean{
        if(this.authService.isLoggedIn()){ return true; }

        this.authService.redirectUrl = url;

        this.router.navigate(['/account']);
        return false;
    }

}
