<header>
  <div class="header-title">
    <a href="#" class="logo">
      <img [src]="logo" alt="Logo">
    </a>
    <h1>Extranet SIEL</h1>
  </div>
  <div class="dmeande-id-header" *ngIf="isAuthenticated">
    <!-- accueil  spécialistes  utilisateurs  contact  retour  déconnecter   -->
    <a class="home-sin pointer" (click)="goToHome()"><i class="fas fa-home fa-2x"></i><span>accueil</span></a>
    <a class="pointer" *ngIf="isHome" [routerLink]="['/home/experts']"><i
        class="fa fa-user-tie fa-2x"></i><span>spécialistes</span></a>
    <!-- <a class="pointer" *ngIf="isHome && isAdmin" [routerLink]="['/home/utilisateurs']"><i
        class="fa fa-users fa-2x"></i><span>utilisateurs</span></a> -->
    <a class="home-sin pointer" (click)="sendEmail()"><i class="fas fa-envelope fa-2x"></i><span>contact</span></a>
    <a class="pointer" *ngIf="isBack" (click)="backToList()"><i
        class="fas fa-arrow-left fa-2x"></i><span>retour</span></a>
    <a class="pointer logout" (click)="logout()"><i class="fas fa-power-off fa-2x"></i><span>se déconn..</span></a>
    <app-logout></app-logout>
  </div>
  <div class="dmeande-id-header" *ngIf="!isAuthenticated">
    <a class="home-sin pointer" *ngIf="isMission" (click)="sendEmail()"><i
        class="fas fa-envelope fa-2x"></i><span>contact</span></a>
  </div>
</header>
