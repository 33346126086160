import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  get isRequesting(): boolean {
    return this.loadingService.getRequestingState();
  }

  constructor(private loadingService: LoadingService) { }

  ngOnInit() { }

}
