import { Injectable } from '@angular/core';

import { Mission } from '../model/Mission';
import { Criterias } from '../model/criterias';
import { Token } from '../model/token';
import { Paging } from '../model/paging';

@Injectable({
    providedIn: 'root'
  })
export class DataService {

    missions: Mission[];
    criterias: Criterias;
    paging: Paging;
    constructor() {
        this.criterias = new Criterias('', '', '');
        this.paging = new Paging(1, 10, 0, 0);
    }

    getToken(): Token {
        return JSON.parse(localStorage.getItem('auth_token_siel'));
    }

    isAdmin(): boolean {
        var token = this.getToken();
        return token.IsAdmin;
    }

    prepareDateUTC(date: Date): Date {
        if (date) {
            var day = date.getDate();
            var month = date.getMonth();
            var year = date.getFullYear();

            var utcDate = Date.UTC(year, month, day);
            date = new Date(utcDate);

            return date;
        }
        else {
            return null;
        }
    }
}
