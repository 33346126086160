import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
  })
export class RecaptchaService {
    constructor(
        private apiService: ApiService
    ) { }

    verify(recaptchaResponse: string) {
        var data = JSON.stringify(recaptchaResponse);
        const get = this.apiService
            .postData('/api/recaptcha/verify', data, new HttpHeaders());

        return get;
    }

}