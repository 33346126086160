import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from './api.service';
import { AppConfiguration } from './app-configuration.service';
import { Token } from '../model/token';
import { Mission } from '../model/mission';
import { SielConstants } from '../siel-constants';
import { GeopResponse } from '../model/geopResponse';
import { OperationDocument } from '../model/operationDocument';

var CryptoJS = require("crypto-js");

@Injectable({
  providedIn: 'root'
})
export class MissionService {

  private currentMission: Mission;

  constructor(
    private appConfiguration: AppConfiguration,
    private apiService: ApiService,
    private httpClient: HttpClient
  ) { }

  getCurrentMission() {
    return this.currentMission;
  }

  getMission(id: number, code: string): Observable<Mission> {
    return this.apiService
      .getData('/api/Mission/Get/' + id + '/' + code, null)
      .pipe(
        map((response: any) => {
          if (response.IsSuccess) {
            this.currentMission = response.Value;
            return response.Value;
          }
          return throwError(response);
        })
        , catchError(error => {
          return throwError(error);
        })
      );
  }

  getMissionById(id: number): Observable<Mission> {
    const token: Token = JSON.parse(localStorage.getItem('auth_token_siel'));

    let headers = new HttpHeaders();
    headers = headers.set('Token', token.AuthToken);

    return this.apiService
      .getData('/api/Mission/' + id + '/Get', headers)
      .pipe(
        map((response: any) => {
          if (response.IsSuccess) {
            this.currentMission = response.Value;
            return response.Value;
          }
          return throwError(response);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getMissionByRef(type: string, reference: string): Observable<Mission> {
    const token: Token = JSON.parse(localStorage.getItem('auth_token_siel'));

    let headers = new HttpHeaders();
    headers = headers.set('Token', token.AuthToken);

    return this.apiService
      .getData('/api/Mission/GetByReference/' + type + '/' + reference, headers)
      .pipe(
        map((response: any) => {
          if (response.IsSuccess) {
            this.currentMission = response.Value;
            return response.Value;
          }
          return throwError(response);
        })
        , catchError(error => {
          return throwError(error);
        })
      );
  }

  getFile(missionId: number, documentId: number): Observable<string> {
    let headers = new HttpHeaders();

    return this.apiService
      .getData('/api/Mission/GetFilePath/' + missionId + '/' + documentId, headers)
      .pipe(
        map((response: any) => {
          if (response.IsSuccess) {
            return response.Value;
          }
          return throwError(response);
        })
        , catchError(error => {
          return throwError(error);
        })
      );
  }

  getOperationFile(operationDocument: OperationDocument): Observable<string> {
    let headers = new HttpHeaders();
    return this.apiService
      .postData('/api/Mission/GetOperationFile', operationDocument, headers)
      .pipe(
        map((response: any) => {
          if (response.IsSuccess) {
            return response.Value;
          }
          return throwError(response);
        })
        , catchError(error => {
          return throwError(error);
        })
      );
  }

  getGEOPUrl(mission: Mission): Observable<string> {
    if (mission && mission.Tiers) {
      var reparateur = mission.Tiers.find(x => x.TypeRole.toLowerCase().includes(SielConstants.Reparateur) &&
        x.Nom.toLowerCase().includes(SielConstants.GEOP));

      if (reparateur) {
        var reference = mission.Id.toString();
        var hashedRef = CryptoJS.MD5(reference).toString();

        return this.httpClient.get(this.appConfiguration.geopMissionExistsUrl + hashedRef)
          .pipe(
            map((response: GeopResponse) => {
              if (response && response.dossier && response.dossier.exists && response.dossier.refContact) {

                var refGeop = response.dossier.refContact.toString();
                var hashedRefGeop = CryptoJS.MD5(refGeop).toString();

                return this.appConfiguration.geopMissionOpenUrl + hashedRefGeop;
              }
            }),
            catchError(error => {
              return throwError(error);
            })
          );
      }
    }
    return EMPTY;
  }
}
