import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AccountComponent } from './account.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPwdComponent } from './forgot-pwd/forgot-pwd.component';
import { UpdatePwdComponent } from './update-pwd/update-pwd.component';

const routes: Routes = [
  {
      path: 'account',
      component: AccountComponent,
      children:[
          { path: 'login', component: LoginComponent },
          { path: 'register', component: RegisterComponent },
          { path: 'forgot-pwd', component: ForgotPwdComponent },
          { path: 'update-pwd', component: UpdatePwdComponent },
          { path: '', redirectTo: '/account/login', pathMatch:'full' },
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AccountRoutingModule { }
