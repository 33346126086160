import { Component, OnInit, AfterViewChecked, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { AuthService } from '../../shared/services/auth.service';
import { NotificationService } from '../../shared/utils/notification.service';
import { RecaptchaService } from 'app/shared/services/recaptcha.service';
import { environment } from 'environments/environment';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { WelcomeDialogComponent } from 'app/shared/dialogs/welcome-dialog/welcome-dialog.component';
declare const grecaptcha: any;

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit, AfterViewChecked {
    recaptchaWidget: any;
    recaptchaResponse: string;
    loginFailed: boolean = false;
    isRequesting: boolean = false;
    userName: string;
    password: string;
    nrFailedAttempts: number = 0;
    showPwd: boolean = false;

    constructor(private authService: AuthService,
        private recaptchaService: RecaptchaService,
        private router: Router,
        private notifService: NotificationService,
        private dialog: MatDialog) { }

    loginForm: NgForm;
    @ViewChild('loginForm', { static: true }) currentForm: NgForm;

    ngAfterViewChecked() {
        this.formChanged();
    }

    formChanged() {
        if (this.currentForm === this.loginForm) { return; }
        this.loginForm = this.currentForm;
        if (this.loginForm) {
            this.loginForm.valueChanges
                .subscribe(data => this.onValueChanged(data));
        }
    }

    onValueChanged(data?: any) {
        if (!this.loginForm) { return; }
        const form = this.loginForm.form;

        for (const field in this.formErrors) {
            // clear previous error message (if any)
            this.formErrors[field] = '';
            const control = form.get(field);

            if (control && control.dirty && !control.valid) {
                const messages = this.validationMessages[field];
                for (const key in control.errors) {
                    this.formErrors[field] += messages[key] + ' ';
                }
            }
        }
    }

    validationMessages = {
        "userName": {
            "required": "Veuillez indiquer le nom d'utilisateur"
        },
        "password": {
            "required": "Veuillez saisir le mot de passe"
        }
    }

    formErrors = {
        'userName': '',
        'password': ''
    };

    ngOnInit() {
        if (this.authService.isLoggedIn()) {
            this.router.navigate(['/home']);
        }
    }

    login() {
        if (this.nrFailedAttempts >= 2 && grecaptcha) {
            this.recaptchaResponse = grecaptcha.getResponse(this.recaptchaWidget);
            if (this.recaptchaResponse.length === 0) {
                this.notifService.printErrorMessage('Veuillez confirmer le captcha svp.')
                return;
            }
            else {
                this.recaptchaService.verify(this.recaptchaResponse).subscribe((result: any) => {
                    if (result && result.success) {
                        this.doLoginRequest();
                    }
                    else {
                        this.notifService.printErrorMessage('Votre captcha n\'est pas valide. Veuillez reéssayer svp.')
                        grecaptcha.reset();
                        return;
                    }
                })
            }
        }
        else {
            this.doLoginRequest();
        }
    }

    doLoginRequest() {
        if (!this.userName || !this.password) {
            this.notifService.printErrorMessage('Veuillez saisir votre identifiant et votre mot de passe s’il vous plaît');
        }
        else {
            this.isRequesting = true;
            this.authService.login(this.userName, this.password)
                .subscribe((response) => {
                    this.isRequesting = false;
                    if (this.authService.isLoggedIn()) {
                        let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/home';
                        this.router.navigate([redirect]);
                        //Loading welcome pop-up window smoothly with timeout
                        setTimeout(x => {
                            this.openWelcomeDialog();
                        }, 520);

                    } else {
                        this.loginFailed = true;
                    }
                },
                    error => {
                        this.nrFailedAttempts++;
                        this.addReCaptcha();
                        this.isRequesting = false;
                        this.notifService.printErrorMessage('Nom d\'utilisateur ou mot de passe incorrect');
                    });
        }
    }

    addReCaptcha() {
        if (this.nrFailedAttempts >= 2 && this.recaptchaWidget === undefined) {
            this.recaptchaWidget = grecaptcha.render(document.getElementById('captcha'), {
                'sitekey': environment.recaptchaSiteKey,
            });
        }
    }

    openWelcomeDialog() {

        const dialogConfig = new MatDialogConfig();

        dialogConfig.minWidth = 320;
        dialogConfig.autoFocus = true;

        this.dialog.open(WelcomeDialogComponent, dialogConfig);
        setTimeout(x => this.dialog.closeAll(), 2600);
        ;
    }

}
