import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Token } from 'app/shared/model/token';


@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.css']
})
export class WelcomeDialogComponent implements OnInit {

  currentUser: string;
  
  ngOnInit(): void { 
    var token = JSON.parse(localStorage.getItem('auth_token_siel'));
    this.currentUser = token.UserFullName;
  }

      constructor(public dialogRef: MatDialogRef<WelcomeDialogComponent>){ }
 
  }