import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { NKDatetimeModule } from 'ng2-datetime/ng2-datetime.js';

import { MissionService } from './services/mission.service';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { DataService } from './services/data.service';

import { ErrorService } from './utils/error.service';
import { NotificationService } from './utils/notification.service';
import { ItemsService } from './utils/items.service';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LogoutComponent } from './logout/logout.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { EuroPipe } from './pipes/euro.pipe';
import { ShowFirstPipe } from './pipes/show-first.pipe';
import { PlaceholderDirective } from './directives/placeholder.directive';
import { RecaptchaService } from './services/recaptcha.service';
import { SafeHTMLPipe } from './pipes/safeHTML.pipe';
import { EmailService } from './services/email.service';
import { DragDropFileDirective } from './directives/dragDropFile.directive';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NKDatetimeModule,
    RouterModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    NKDatetimeModule,
    FooterComponent,
    HeaderComponent,
    SpinnerComponent,
    EuroPipe,
    ShowFirstPipe,
    DragDropFileDirective,
    PlaceholderDirective,
    SafeHTMLPipe
  ],
  declarations: [
    FooterComponent,
    HeaderComponent,
    LogoutComponent,
    SpinnerComponent,
    EuroPipe,
    ShowFirstPipe,
    PlaceholderDirective,
    DragDropFileDirective,
    SafeHTMLPipe
  ],
  providers: [
    ApiService,
    AuthGuardService,
    ErrorService,
    NotificationService,
    ItemsService,
    DataService,
    MissionService,
    RecaptchaService,
    EmailService
  ]
})
export class SharedModule {}
