export class Criterias {
    constructor(
        public Missionnement: string,
        public Contentieux: string,
        public Delegation: string
    ){
        this.TypeDossier = [];
        this.TypeDossierAllSelected = false;
        this.EtatDossier = [];
        this.EtatDossierAllSelected = false;
        this.Departements = [];
        this.DepartementsAllSelected = false;
    }
    public NumDossier: string;
    public NomAffaire: string;
    public Police: string;
    public RefClient: string;
    public RefIntermediaire: string;
    public IdAssure: number;
    public DateReceptionStart: Date;
    public DateReceptionEnd: Date;
    public DateSinistreStart: Date;
    public DateSinistreEnd: Date;
    public NomGestionnaire: string;
    public TypeDossier: any[];
    public TypeDossierAllSelected: boolean;
    public EtatDossier: any[];
    public EtatDossierAllSelected: boolean;
    public Departements: any[];
    public DepartementsAllSelected: boolean;
}
