<footer>
  <div *ngIf="!isRequesting" class="footer_ctr">
    <div id="menu1">
      <footer id="footer" class="region region-footer">
        <div id="block-menu-menu-footer" class="block block-menu first last odd" role="navigation">
          <ul class="menu">
            <li class="m">
              <a href="" title="" class="">Mentions légales</a>
            </li>
            <li class="">
              <a href="" title="" class="">Plan du site</a>
            </li>
            <li class="">
              <a href="" title="" class="">Partenaires</a>
            </li>
            <li class="">
              <a href="/" title="" class="">Contact</a>
            </li>
            <li class="">
              <a href="/" title="" class="">Trouver une agence</a>
            </li>
            <li class="">
              <a href="https://www.saretec.fr/" title="" class="">© Saretec {{ currentYear }}</a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
    <div id="menu2">
      <p>Retrouvez-nous<br><span>sur</span>
        <a href="https://facebook.com/Groupe-Saretec-329638480541987" target="_blank" class="social"><i
            class="fab fa-facebook-f fa-2x"></i></a>
        <a href="https://www.linkedin.com/company/saretec-france" target="_blank" class="social"><i
            class="fab fa-linkedin-in fa-2x"></i></a>
        <a href="https://viadeo.com/fr/company/saretec" target="_blank" class="social"><i
            class="fab fa-viadeo fa-2x"></i></a></p>
    </div>
  </div>
</footer>
