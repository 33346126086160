import { Injectable } from '@angular/core';

declare let alertify: any;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _notifier: any = alertify;

  constructor() { }

  openConfirmationDialog(message: string, okCallback: () => any){
    this._notifier.confirm(message, (e) => {
      if(e){
        okCallback();
      }
    });
  }

  openAlertDialog(message: string, okCallback: () => any){
    this._notifier.alert(message, (e) => {
      if(e){
        okCallback();
      }
    });
  }

  printSuccessMessage(message: string){
    this._notifier.success(message);
  }

  printErrorMessage(message: string){
    this._notifier.error(message);
  }
  
  printMessage(message: string, delay:number=5000){
    this._notifier.log(message, '', delay);
  }
}
