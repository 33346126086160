import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ApiService } from './api.service';
import { Base64 } from '../utils/Base64';
import { Token } from '../model/token';
import { UserCredential } from '../model/user-credential';
var CryptoJS = require("crypto-js");
import { PasswordUpdate } from '../model/passwordUpdate';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Redirect url
  redirectUrl: string;

  private loggedIn = false;
  currentUser: string;

  constructor(private apiService: ApiService) {
  }

  login(userName: string, password: string) {
    let hashedPassword = this.hashPassword(userName, password);

    const base = new Base64();
    const credentials = 'Basic ' + base.encode(userName + ':' + hashedPassword);
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', credentials);

    const post = this.apiService.postData('/api/login', null, headers);
    post.subscribe(
      (res: any) => {
        if (res.IsSuccess) {
          localStorage.setItem('auth_token_siel', JSON.stringify(res.Value));
        }
        this.loggedIn = res.IsSuccess;
        this.currentUser = userName;
      },
      error => {
        this.loggedIn = false;
        return throwError(error);
      }
    );
    return post;
  }

  hashPassword(userName: string, password: string) {
    
    if (!userName || !password){
      return null;
    }
    
    var pwd = (userName.toLowerCase() + password).toString();
    var hash = CryptoJS.SHA256(pwd);
    var hexShabase64 = hash.toString(CryptoJS.enc.Base64).replace("=", "");

    return hexShabase64;
  }

  loginAuto(userName: string, sign: string) {
    let hashedPassword = this.hashPassword(userName, sign);

    const data = JSON.stringify({ Login: userName, Sign: hashedPassword });
    const post = this.apiService.postData(
      '/api/auto-login',
      data,
      new HttpHeaders()
    );
    post.subscribe((res: any) => {
      if (res.IsSuccess) {
        localStorage.setItem('auth_token_siel', JSON.stringify(res.Value));
        this.loggedIn = res.IsSuccess;
        this.currentUser = userName;
        return res;
      }
      return throwError(res);
    }, error => {
      return throwError(error);
    });
    return post;
  }

  logout() {
    const token: Token = JSON.parse(localStorage.getItem('auth_token_siel'));

    if (!token) {
      this.loggedIn = false;
      return null;
    }

    let headers = new HttpHeaders();
    headers = headers.set('Token', token.AuthToken);

    const data = JSON.stringify(token.AuthToken);

    const post = this.apiService.postData('/api/logout', data, headers);

    localStorage.removeItem('auth_token_siel');
    this.loggedIn = false;

    return post;
  }

  isLoggedIn() {
    return this.loggedIn || localStorage.getItem('auth_token_siel') != null;
  }

  getCredential(credential: UserCredential): Observable<any> {
    const data = JSON.stringify(credential);

    const post = this.apiService.postData(
      '/api/User/GetCredentials',
      data,
      new HttpHeaders()
    );
    post.subscribe(res => {
      return res;
    });
    return post;
  }

  resetPassword(identifiant: string): Observable<any> {

    const post = this.apiService.postData(
      '/api/User/ResetPassword',
      JSON.stringify(identifiant),
      new HttpHeaders()
    );

    return post;
  }

  updatePassword(model: PasswordUpdate): Observable<any> {
    var passwordHashed = this.hashPassword(model.UserName, model.Password);
    model.Password = passwordHashed;

    const post = this.apiService.postData('/api/User/UpdatePassword', model, new HttpHeaders());

    return post;
  }

  getCurrentUser() {
    return this.currentUser;
  }

}
