import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isRequesting: boolean = false;

  constructor() { }

  setRequestingState(value: boolean): void {
    this.isRequesting = value;
  }

  getRequestingState(): boolean {
    return this.isRequesting;
  }
}
