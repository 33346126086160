import { Injectable } from '@angular/core';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  constructor() { }

  sortBy<T>(array :Array<T>, property:string, order?:string): Array<T>{
    let result = _.sortBy(array, property);
    if(order == 'DESC'){
      result = _.reverse(result);
    }
    return result;
  }

}
