<div class="body-id-header">
  <div class="title-id headerTitleDiv">
    <h2>Demande de mot de passe </h2>
    <p>Veuillez saisir votre identifiant svp. Un email vous sera envoyé.</p>
  </div>
</div>
<div class="body-id">
  <div class="form-id headerDescriptionDiv">
    <form #requestForm="ngForm" novalidate>
      <img src="assets/img/img-anchor-id.jpg" class="hide" alt="anchor">
      <div class="col-id col-id-1 inputDiv">
        <div style="margin-bottom:4%">
          <mat-form-field>
            <input matInput type="text" id="txt_identifiant" placeholder="Identifiant" name="identifiant" class="identifiant"
              [(ngModel)]="identifiantModel" #identifiant="ngModel" required />
          </mat-form-field>
        </div>
        <div id="captcha" style="margin-bottom:4%"></div>
        <div class="action-buttons">
          <button class="send-button" mat-flat-button color="primary" (click)="resetPasswordClick()" *ngIf="requestForm.form.valid">Envoyer</button>
          <button mat-flat-button color="primary" [routerLink]="['/account']">Annuler</button>
        </div>
      </div>
    </form>
  </div>
</div>
