import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LientransparentComponent } from './lientransparent/lientransparent.component';

const routes: Routes = [
    {
        path:'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    { path:'lien_transparent.aspx', component: LientransparentComponent},
    { path:'', redirectTo: '/home', pathMatch: 'full'},
    { path:'**', redirectTo: '/home', pathMatch:'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
