import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AccountModule } from './account/account.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppConfiguration } from './shared/services/app-configuration.service';
import { AppComponent } from './app.component';
import { LientransparentComponent } from './lientransparent/lientransparent.component';
import { UpdatePwdComponent } from './account/update-pwd/update-pwd.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { WelcomeDialogComponent } from 'app/shared/dialogs/welcome-dialog/welcome-dialog.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorIntlFrench } from './shared/utils/MatPaginatorIntlFrench';
registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    LientransparentComponent,
    UpdatePwdComponent,
    WelcomeDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    AccountModule,
    AppRoutingModule,
    MatDialogModule,
    MatInputModule,
    MatNativeDateModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule
  ],
  entryComponents: [
    WelcomeDialogComponent
  ],
  providers: [
    AppConfiguration,
    {
      provide: APP_INITIALIZER,
      useFactory: AppConfigurationFactory,
      deps: [AppConfiguration, HttpClient],
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlFrench },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function AppConfigurationFactory(
  appConfig: AppConfiguration) {
  return () => appConfig.ensureInit();
}
