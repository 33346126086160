import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfiguration } from './app-configuration.service';
import { share } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient, private appConfiguration: AppConfiguration) { }

  getData(url: string, headers: HttpHeaders) {
    return this.http.get(this.appConfiguration.apiUrl + url, {
      headers: headers
    }).pipe(share());
  }

  postData(url: string, data: any, headers: HttpHeaders) {
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Access-Control-Allow-Origin', '*');

    return this.http.post(this.appConfiguration.apiUrl + url, data, {
      headers: headers
    }).pipe(share());
  }

}
