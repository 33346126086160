<div>
  <div class="content" mat-dialog-content>
    <p>
      <img src="assets/img/logo_saretec.png" alt="Saretec Logo">
      <br/>
      Bienvenu(e) sur Siel, <span>{{currentUser}} </span>!
      <br/><br/>
      Bonne utilisation
      <br/>
    </p>
  </div>
</div>